import { LoginRnRStar, addIcon, coinIcon } from "~/assets/assets";
import { CoinsTransactionsModal } from "~/components/coins_transactions_modal";
import { toIndianNumber } from "~/utils/number";
import { useModal } from "~/components/modal";
import { Setter, Show } from "solid-js";

type HomeRnrHeaderProps = {
  name: string;
  observerRef?: HTMLDivElement;
  coinsAvailable: number;
  setRefreshCoins: Setter<boolean>;
};

export function HomeRnrHeader(props: HomeRnrHeaderProps) {
  const { openModal } = useModal()!;

  return (
    <div class="home mx-3 flex flex-col items-center justify-center rounded-b-[24px] bg-homepageMobileGradient p-4 md:bg-whiteBg md:py-0 lg:mt-8">
      <div class="top-0  flex flex-col items-center justify-center bg-contain bg-center bg-no-repeat lg:h-[300px]  lg:w-[950px]  lg:bg-homeRNRHeaderBg">
        <Show when={props.name}>
          <div class="pb-1 text-f12Bold tracking-[7%] text-[#8B651B] lg:text-orange">
            {props.name.toLocaleUpperCase()}
          </div>
        </Show>
        <div class="flex  items-center gap-2">
          <div class="hidden h-2 w-2 lg:flex">
            <LoginRnRStar />
          </div>
          <div class="h-2 w-2 lg:hidden">
            <LoginRnRStar fill="#8B651B" />
          </div>
          <div class="hidden h-3.5 w-3.5 lg:flex">
            <LoginRnRStar />
          </div>
          <div class="h-3.5 w-3.5 lg:hidden">
            <LoginRnRStar fill="#8B651B" />
          </div>
          <div
            class="font-sacramento text-h1 tracking-[0px] text-[#8B651B] lg:text-orange"
            style={{
              "font-weight": "400",
            }}
          >
            Congratulations
          </div>
          <div class="hidden h-3.5 w-3.5 lg:flex">
            <LoginRnRStar />
          </div>
          <div class="h-3.5 w-3.5 lg:hidden">
            <LoginRnRStar fill="#8B651B" />
          </div>
          <div class="hidden h-2 w-2 lg:flex">
            <LoginRnRStar />
          </div>
          <div class="h-2 w-2 lg:hidden">
            <LoginRnRStar fill="#8B651B" />
          </div>
        </div>
        <div
          ref={props.observerRef}
          class="pb-4 text-center text-h4 lg:pb-6 lg:text-h1"
        >
          Shop on 400+ brands with <br /> your coins
        </div>
        <div>
          <div
            onClick={(event) => {
              event.stopPropagation();
              openModal(() => {
                return (
                  <CoinsTransactionsModal
                    setRefreshCoins={props.setRefreshCoins}
                    balance={props.coinsAvailable}
                    userName={props.name}
                    enableRedeemCode={true}
                    title="Your coins"
                    subtitle="Use it to get gift cards of your choice"
                  />
                );
              });
            }}
            class="flex cursor-pointer flex-row items-center rounded-[50px] border bg-white p-2  pr-3 text-mediumBold lg:mb-10"
            style={{
              "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
              border: "0.5px solid rgba(192, 192, 192, 1)",
            }}
          >
            <div class="pr-[6px]">
              <img src={coinIcon} class="h-6 w-6" />
            </div>
            Balance: {toIndianNumber(props.coinsAvailable)}
          </div>
        </div>
      </div>
    </div>
  );
}
